<template>
  <div class="page">
    <h2 class="main-title">{{title}}</h2>
    <SubMenu/>
    <router-view/>
    <h2 class="main-title" v-if="searchLoading">Найдено {{getSMP.length}} стандарта</h2>
    <div class="responsive" v-if="searchLoading">
      <table class="table table-search table-link">
        <thead>
          <tr>
            <th
              v-for="header in headers"
              :key="header.text"
              class="text-center">
              {{header.text}}
              <div class="search">
                <input type="text" v-model="header.input" @input="setSearch({column:header.value, string:header.input})">
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(smp, index) in getSMP"
            @click="routLink(smp.id)"
            :key="index"
          >
            <td class="text-left"><div class="stage">{{smp.data}}</div></td>
            <td class="text-center">{{smp.name}}</td>
            <td class="text-center"><div class="item">{{smp.clinicalRecommendations}}</div></td>
            <td class="text-center"><div class="item">{{smp.mkbCode}}</div></td>
            <td class="text-center"><div class="item">{{smp.mkbName}}</div></td>
            <td class="text-center"><span v-if="smp.ageGroup">{{smp.ageGroup}}</span></td>
            <td class="text-center"><div class="item">{{smp.medicalCareTypes}}</div></td>
            <td class="text-center">{{smp.status}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import SubMenu from '@/components/SubMenu/SubMenu.vue'
  import {mapGetters, mapMutations} from 'vuex'

  export default {
    name: 'Search',
    components: {
      SubMenu
    },
    data () {
      return {
        title: 'Поиск по Услуге/ЛС',
        load: false,
        headers: [
          {
            text: '№ и дата',
            value: 'data',
          },
          { text: 'Наименование проекта', value: 'name', input: '' },
          { text: 'Клиническая рекомендация', value: 'clinicalRecommendations' , input: ''},
          { text: 'МКБ-10', value: 'mkbName' , input: ''},
          { text: 'Заболевание', value: 'mkbCode', input: '' },
          { text: 'Возрастная группа', value: 'ageGroup', input: '' },
          { text: 'Вид и условия оказания медицинской помощи', value: 'medicalCareTypes', input: '' },
          { text: 'Статус', value: 'status', input: '' }
        ],
      }
    },
    computed : {
      ...mapGetters({
          getSMP: 'smp/getSMP',
          searchLoading: 'smp/searchLoading'
      }),
    },
    methods: {
      ...mapMutations({
        setSearch: 'smp/setSearch',
        menuMutations: 'login/menuMutations'
      }),
      routLink(id) {
        this.$router.push({ name: 'pasport', params: { id: id } })
      },
    },
    mounted() {
      this.menuMutations(this.$route.matched[0].name)
    }
  }
</script>
